<template>
  <div class="container">
    <div class="qrcode-wrapper">
      <p class="title">请使用微信扫码充值</p>
      <vue-qr :logoSrc="logoSrc" :text="qrcodeText" :size="200" width="300" height="300"></vue-qr>
    </div>
    

  </div>
</template>

<script>
// import VipPackage from "./components/VipPackage.vue";
import { getToken, removeToken } from '@/utils/auth'
import vueQr from 'vue-qr'
const host = process.env.VUE_APP_HOST_NAME
const token = getToken()
export default {
  components: {
    vueQr
  },
  data() {
    return {
      qrcodeText:`${host}/mobile/Recharge?token=${token}`,
      // qrcodeText:`${host}/mobile/Recharge`,
      logoSrc:require("@/assets/imgs/logo-qrcode.jpeg" ) 
    };
  },
  methods: {},
  computed: {},
  mounted() {
  
  },
};
</script>

<style lang="stylus" scoped>
.container {
  display: block;
  box-sizing: border-box;
  // width: 100%;
  .qrcode-wrapper{
    display: block;
    box-sizing: border-box;
    width :300px;
    margin: 0 auto;
    .title{
      text-align: center;
    }
  }
}
</style>